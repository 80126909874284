import React, { Component } from 'react'
import { Link } from "react-router-dom";
import "./VideoPage.css"

export class AzureIntegrationServicesPage extends Component {
    render() {
        return (
            <div className="portfolio-page">

                <Link className="App-link" to="/">Home</Link>

                <h1>Azure Integration Services 1 Minute Overview</h1>


                <div className="portfolioDemoReel" >
                    <iframe title="DemoReel" className="DemoReel" src="https://www.youtube.com/embed/4krAUCdFpgU?rel=0" allow="modestbranding; clipboard-write; encrypted-media; picture-in-picture" allowfullscreen></iframe>

                </div>

            </div>
        )
    }
}

export default AzureIntegrationServicesPage
