import React from 'react';
import './App.css';
import { Route , Switch} from "react-router-dom";
import HomePage from "./components/HomePage";
import AppServicePage from "./components/AppServicePage";
import FusionDevelopmentPage from "./components/FusionDevelopmentPage";
import AzureIntegrationServicesPage from "./components/AzureIntegrationServicesPage";
import NotFound from "./components/NotFound";

function App() {
  return (
    <div className="App">

          <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/app-service-in-1-minute" component={AppServicePage} />
          <Route path="/fusion-development-in-1-minute" component={FusionDevelopmentPage} />
          <Route path="/azure-integration-services-in-1-minute" component={AzureIntegrationServicesPage} />
          <Route component={NotFound} />
          </Switch>

      
    </div>
  );
}

export default App;
