import React, { Component } from 'react'
import { Link } from "react-router-dom";

export class NotFound extends Component {
    render() {
        return (
            <div>
                <h1>404 - Sorry, We couldnt find that page</h1>
                <Link className="App-link" to="/">Home</Link>
            </div>
        )
    }
}

export default NotFound
