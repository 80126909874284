import React, { Component } from 'react'
import {Link} from "react-router-dom";
import "./HomePage.css"
import bioPic from "../images/ProfilePicGreySweatshirtBayPicCropped.jpg";
import msftPic from "../images/MicrosoftEmployeeBadgePicIDCovered.jpg";

export class HomePage extends Component {
    render() {
        return (
            <div>
                <header className="App-header">

                    <h1>
                        Tech Talent Transformer
                    </h1>
                    <h3>Are you getting replaced by Artificial Intelligence?</h3>
                    <h3>Are you stuck in a career without any opportunity for advancement?</h3>
                    <h3>Do you want to make an extra $30k, $50k, or $100k MORE then you are making now?</h3>

                    <Link className="App-link" to={{ pathname: "https://forms.gle/8JvQUPkgWPso5y9A8" }} target="_blank">Apply For Tech Talent Transformer</Link>

<p>Hi, I’m Frankie Riviera</p>
<img
            className="bio-pic"
            src={bioPic}
            alt="Frankie G Riviera at table smiling"
          />
<p>I grew up around the software technology world.</p>
<p>I have been building software for years and today I work for Microsoft in Enterprise Technical Sales making well into a <span className="bold">6 figure salary with great benefits and opportunities to grow.</span> </p>

<p>Several years ago, I had no job, and no direction for my future career.</p>
<p>I had just finished getting my bachelors degree.</p>
<p>But I came to the tragic realization that my college degree had not prepared me for getting a job in the real world.</p>
<p>I felt useless, unsure of myself, and afraid for my future.</p>
<p>I settled for a local internship working in a couple different industries that I felt would be the rest of my life.</p>
<p>I felt like I would never amount to anything in my career.</p>
<p>But then, by the grace of God, while working at this internship, I had the greatest epiphany of my life.</p>
<p>I learned what and how to truly learn.</p>
<p>I now live my life by the following statement.</p>
<p><span className="bold">Everything is hard until you know it.</span></p>
<p>If you think it sounds simple...You're right.</p>
<p>If you need help embracing it, I may be able to help you.</p>

<Link className="App-link" to={{ pathname: "https://forms.gle/8JvQUPkgWPso5y9A8" }} target="_blank">I am ready to fully embrace a learner's mindset</Link>

<p>Before this epiphany, I thought I could only learn by information being spoon feed to me by someone else who was an expert in something. I never considered myself an expert in anything.</p>
<p>Even though I had grown up around the software technology industry, I had never thought I had what it took to get a real job there.</p>
<p>Once I embraced this epiphany, my whole life transformed, I put myself to work learning as much as I could, whenever I could, about software, technology, job hunting, and business.</p>
<p>When I was ready, I had a software engineering job at a local startup with a high paying salary <span className="bold">in less than 2 months.</span> </p>
<p>I was proud to enter the technology world as an expert, and as time went on, I knew there was opportunity for more.</p>
<p>I decided to put my job hunting skills and software knowledge to the test by pursuing a job at <span className="bold">the largest software company in the world, Microsoft Corporation.</span> </p>
<p>There was additional learning, networking, and hard work involved.</p>
<p>But I pushed myself out of my comfort zone.</p>
<p>I won my dream job at Microsoft.</p>
<p>Now I am making well into 6 figures, with great benefits, opportunities to grow, and great job security.</p>

<img
            className="bio-pic"
            src={msftPic}
            alt="Frankie G Riviera with Microsoft Employee Badge"
          />

<Link className="App-link" to={{ pathname: "https://forms.gle/8JvQUPkgWPso5y9A8" }} target="_blank">I am ready for 6 figures, growth potential and job security too!</Link>

<p><span className="bold">This could be you in 1 year, or 6 months, or sooner!</span></p>
<p>If.......<span className="bold">you want it bad enough.</span></p>
<p>That is where I come in.</p>
<p>I want to help you win your dream job!</p>
<p>I will show you step by step what I did so you can do it too!</p>
<p><span className="bold">How would you feel if instead of being replaced by AI, you had job security for yourself and your family?</span></p>
<p><span className="bold">How would you feel if instead of being stuck in your dead-end job, employers were reaching out to YOU, begging to give you a job with unlimited growth potential?</span></p>
<p><span className="bold">How would you feel if instead of barely making ends meet, you were comfortably making $30k, $50k, or $100 MORE then you are making now?</span></p>
<p>All without needing a college degree.</p>
<p>With My Tech Talent Transformer coaching program, you will learn what technology opportunities align with your passions, what and where to learn what’s needed for your dream job, and how to win your dream job.</p>
<p>Interested? Then keep reading…</p>
<p><span className="bold">This program is exclusive.</span></p>
<p><span className="bold">I can only accept a handful of people at this time.</span></p>
<p>I’ll be your coach to help you be successful along the way.</p>
<p>But there is only one of me, so I can only help those who are willing and ready to truly invest in themselves and their future.</p>
<p>I can guide you, coach you, and point you in the right direction of what you need to learn and do, but ultimately you will need to prove to yourself that your dream job is worth it by taking action.</p>
<p>If you are ready to get out of your dead-end job and secure a high-paying job with unlimited growth potential and job security in the technology industry, apply for the Tech Talent Transformer below.</p>


                    {/* <Link className="App-link" href="https://forms.gle/8JvQUPkgWPso5y9A8">Apply For Tech Talent Transformer</Link> */}
                    <Link className="App-link" to={{ pathname: "https://forms.gle/8JvQUPkgWPso5y9A8" }} target="_blank">Apply For Tech Talent Transformer</Link>
                    {/* <Link className="App-link" to="/fusion-development-in-1-minute">Fusion Development 1 Minute Overview</Link> */}
                    {/* <Link className="App-link" to="/azure-integration-services-in-1-minute">Azure Integration Services 1 Minute Overview</Link> */}
                    <p>&copy; Tech Talent Transformer 2023</p>

                </header>
            </div>
        )
    }
}

export default HomePage
